.burger {
  background-color: transparent;
  border: 0;
  padding: 0;
  
  span {
    position: relative;
    display: block;
    height: 3px;
    width: 32px;
    background-color: currentColor;
    transition: all 0.3s linear;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &._active {
    span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 9px;
      }
  
      &:nth-child(2) {
        transform: scaleX(0);
        opacity: 0;
      }
  
      &:nth-child(3) {
        transform: rotate(-45deg);
        top: -5px;
      }
    }
  }
}