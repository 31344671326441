.showroom {
  &__image {
    position: relative;
    height: rem(625);
    border-radius: rem(15);
    overflow: hidden;

    @include media-breakpoint-down(md) {
      height: rem(246);
      margin-bottom: rem(50)
    }

    img {
      @include cover-image();
    }

    &--mobile {
      display: none;

      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    &:not(&--mobile) {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__address {
    margin-bottom: $d-space;

    @include media-breakpoint-down(md) {
      margin-bottom: rem(20);
    }

    p {
      &:not(:last-child) {
        margin-bottom: rem(30);

        @include media-breakpoint-down(md) {
          margin-bottom: rem(20);
        }
      }
    }

    a {
      color: $color-black;
    }
  }

  &__btn {
    margin-top: $d-space;

    @include media-breakpoint-down(md) {
      display: block;
      width: 100%;
      margin-top: rem(30);
    }
  }

  & + .section {
    padding-top: 0;
  }
}