.friday-form {
  padding: rem(40) rem(20) rem(40) rem(30);
  border-radius: rem(15);
  border: 1px solid $color-white;
  background: rgba($color-total-black, 0.60);

  @include media-breakpoint-down(xs) {
    padding: rem(40) rem(10) rem(40);
    border-radius: 0;
    border: none;
    margin: 0 -10px;
  }

  &__title {
    margin-bottom: rem(30);
  }

  &__text {
    margin-bottom: rem(30);
  }

  &__counter {
    margin-bottom: rem(50);

    @include media-breakpoint-down(md) {
      margin-bottom: rem(40);
    }
  }

  &__group {
    &:not(:last-child) {
      margin-bottom: rem(30);

      @include media-breakpoint-down(md) {
        margin-bottom: rem(22);
      }
    }
  }
}
