.product-map {

  &__inner {
    position: relative;
    width: vw(688);
    height: vw(572);
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: auto;
      overflow: hidden;
      margin-bottom: rem(50);
    }
  }

  &__list {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__points {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__point {
    --left: 0;
    --top: 0;

    position: absolute;
    top: calc(((var(--top) / var(--gfs)) * 100) * 1vw);
    left: calc(((var(--left) / var(--gfs)) * 100) * 1vw);

    @include media-breakpoint-down(sm) {
      top: calc(var(--t-m) * 1%);
      left: calc(var(--l-m) * 1%);
    }
  }

  &__image {
    img {
      display: block;
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      user-select: none;

      img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__item {
    padding-bottom: rem(30);
    border-bottom: 1px solid $color-grey;

    &:not(:last-child) {
      margin-bottom: rem(20);
    }

    &:first-child {
      padding-top: rem(20);
      border-top: 1px solid $color-grey;
    }

    &-title {
      margin-bottom: rem(5);
      font-weight: 500;
      font-size: rem(18);
      line-height: 1.2;
    }

    &-text {
      color: $color-grey;
      font-size: rem(16);
      line-height: 1.3;
    }
  }
}