/**
 * Spacing
 * Used by Utility _spacing.scss
 */
$space-auto:  auto !default;
$space-0:     0 !default;
$space-025:   .25rem !default;
$space-050:   .5rem !default;
$space-075:   .75rem !default;
$space-1:     1rem !default;
$space-2:     2rem !default;
$space-3:     3rem !default;
$space-4:     4rem !default;
$space-5:     5rem !default;
$space-6:     6rem !default;

$d-space:        3.75rem !default;
$d-space-tablet: 2rem !default;
$d-space-mobile: 1.875rem !default;

// Margin and padding values array
$space-values : (
  auto   : $space-auto,
  0      : $space-0,
  '025'  : $space-025,
  '050'  : $space-050,
  '075'  : $space-075,
  1      : $space-1,
  2      : $space-2,
  3      : $space-3,
  4      : $space-4,
  5      : $space-5,
  6      : $space-6
) !default;

$space-prefixes : (
  p  : padding,
  pt : padding-top,
  pr : padding-right,
  pb : padding-bottom,
  pl : padding-left,
  px : (padding-left, padding-right),
  py : (padding-top, padding-bottom),
  m  : margin,
  mt : margin-top,
  mr : margin-right,
  mb : margin-bottom,
  ml : margin-left,
  mx : (margin-left, margin-right),
  my : (margin-top, margin-bottom)
) !default;
