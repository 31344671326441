.catalog-card {
  border: 1px solid #000;
  border-radius: rem(15);
  overflow: hidden;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(30) rem(20);

    @include media-breakpoint-down(md) {
      padding: rem(20);
    }
  }

  &__image {
    position: relative;
    display: block;
    padding-bottom: 80%;

    img {
      @include cover-image();
    }
  }

  &__title {
    margin-bottom: rem(10);
  }

  &__btn {
    margin-top: rem(20);
  }
}