.tabs {
  &-nav {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(55);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      //max-width: rem(240);
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: rem(30);

        @include media-breakpoint-up(md) {
          margin-right: rem(30);
          margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
          margin-right: rem(60);
        }
      }
    }

    &__link {
      position: relative;
      display: flex;
      flex-direction: column;
      padding-bottom: rem(10);
      padding-right: rem(24);
      color: $color-light-grey;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s linear, visibility .3s linear;
      }

      &._active {
        color: $color-black;

        &::after {
          opacity: 1;
          visibility: visible;
        }
      }

      span {
        font-size: rem(30);
        line-height: 1.1;

        @include media-breakpoint-down(md) {
          font-size: rem(24);
        }

        &:last-child {
          margin-top: rem(10);
          font-size: rem(18);

          @include media-breakpoint-down(md) {
            font-size: rem(14);
          }
        }
      }
    }
  }

  &__text {
    margin-bottom: rem(64);
    max-width: rem(970);
    font-size: rem(24);
    line-height: 1.1;

    @include media-breakpoint-down(sm) {
      margin-bottom: rem(50);
      font-size: rem(18);
    }
  }

  &-content {
    &__item {
      display: none;

      &._active {
        display: block;
      }
    }
  }
}
