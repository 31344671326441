.form-section {
  position: relative;
  padding-top: rem(86);
  padding-bottom: rem(100);
  color: $color-white;

  @include media-breakpoint-down(md) {
    padding-top: rem(50);
    padding-bottom: rem(50);
  }

  &__inner {
    position: relative;
    z-index: 3;
  }

  &__title {
    margin-bottom: $d-space;
    font-weight: 400;

    @include media-breakpoint-down(md) {
      margin-bottom: rem(20);
    }
  }

  &__image {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    }
    
    img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__right {
    @include media-breakpoint-down(md) {
      margin-top: 60px;
    }
  }
}