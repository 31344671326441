.contacts-section {
  min-height: rem(680);
  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding: 0;
    min-height: 0;
  }
  
  &__inner {
    display: flex;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    @include media-breakpoint-down(md) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__left,
  &__right {
    max-width: 50%;
    width: 100%;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
  }

  &__left {
    color: $color-white;
    background-color: $color-black;
    padding-top: rem(136);
    padding-bottom: rem(114);

    @include media-breakpoint-down(md) {
      padding-top: rem(50);
      padding-bottom: rem(50);
    }
  }

  &__right {
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-down(lg) {
      height: rem(600);
    }

    @include media-breakpoint-down(md) {
      height: 400px;
    }
  }

  &__content {
    max-width: rem(780);
    padding-left: rem(60);

    @include media-breakpoint-down(lg) {
      padding-left: rem(30);
    }

    @include media-breakpoint-down(md) {
      max-width: rem(720);
      margin-left: auto;
      margin-right: auto;
      padding-left: 10px;
      padding-right: 10px;
    }

    @include media-breakpoint-down(sm) {
      max-width: rem(540);
    }
  }
}