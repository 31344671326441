.logo {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: rem(40);
  line-height: 1;
  color: currentColor;

  @include media-breakpoint-down(md) {
    font-size: rem(20);
  }

  &__dots {
    display: flex;
    margin-right: rem(15);

    @include media-breakpoint-down(lg) {
      margin-right: 0;
    }

    @include media-breakpoint-down(md) {
      margin-right: 8px;
    }
  }

  &__text {
    opacity: 0;
    
    @include media-breakpoint-down(lg) {
      display: none;
    }

    @include media-breakpoint-down(md) {
      display: block;
      margin-top: -2px;
    }
  }

  &__dot {
    flex-basis: auto;
    flex-shrink: 0;
    width: rem(30);
    height: rem(30);
    border-radius: 50%;
    background-color: $color-yellow;

    @include media-breakpoint-down(lg) {
      width: rem(25);
      height: rem(25);
    }

    @include media-breakpoint-down(md) {
      width: rem(15);
      height: rem(15);
    }


    &:nth-child(1) {
      background-color: $color-dark-red;
    }

    &:nth-child(2) {
      background-color: $color-orange;

      transform: translateX(-40px);

      @include media-breakpoint-down(lg) {
        transform: translateX(-35px);
      }

      @include media-breakpoint-down(md) {
        transform: translateX(-20px);
      }
    }

    &:nth-child(3) {
      transform: translateX(-80px);

      @include media-breakpoint-down(lg) {
        transform: translateX(-70px);
      }

      @include media-breakpoint-down(md) {
        transform: translateX(-40px);
      }
    }

    &:not(:last-child) {
      margin-right: rem(10);

      @include media-breakpoint-down(md) {
        margin-right: rem(5)
      }
    }
  }
}
