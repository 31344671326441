.hero-section {
  position: relative;
  display: flex;
  align-items: flex-end;
  min-height: rem(800);
  padding-top: rem(100);
  padding-bottom: rem(50);
  overflow: hidden;
  background-color: $color-dark;
  color: $color-white;

  @include media-breakpoint-down(md) {
    padding-top: rem(135);
    padding-bottom: rem(60);
    min-height: 0;
  }

  @include media-breakpoint-down(xs) {
    padding-bottom: 0;
  }

  &__logos {
    display: none;
    margin-bottom: rem(70);

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    align-items: flex-end;
    z-index: 4;
    height: 100%;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      align-items: center;
    }

    @include media-breakpoint-down(md) {
      align-items: flex-start;
    }
  }

  &__side {
    flex: 0 0 auto;
    width: rem(454);
    margin-left: rem(60);
    opacity: 0;

    @include media-breakpoint-down(lg) {
      width: rem(600);
      max-width: 100%;
      margin-left: 0;
    }

    @include media-breakpoint-down(1500px) {
      margin-left: rem(30);
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &__content {
    max-width: rem(958);
    margin-bottom: rem(45);

    @include media-breakpoint-down(md) {
      margin-bottom: rem(40);
    }
  }

  &__subtitle {
    margin-bottom: rem(20);
    max-width: rem(402);
    color: $color-light-grey;

    @include media-breakpoint-down(md) {
      display: none;
      max-width: rem(214);
    }
  }

  &__title {
    max-width: rem(958);
    font-weight: 400;

    @include media-breakpoint-down(md) {
      max-width: rem(370);
    }
  }

  &__bottom {
    display: flex;
    align-items: flex-start;
    margin-top: rem(60);

    @include media-breakpoint-down(md) {
      display: none;
      flex-direction: column;
      margin-top: rem(50);
    }
  }

  &__text {
    color: $color-light-grey;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      margin-bottom: 50px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: rem(370);
    }
  }

  &__btn {
    display: none !important;
    margin-left: $d-space;
    margin-top: rem(4);

    @include media-breakpoint-down(lg) {
      margin-left: $d-space-tablet;
    }

    @include media-breakpoint-down(md) {
      display: block;
      width: 100%;
      margin-top: 0;
      margin-left: 0;
    }
  }

  &__illustration {
    position: absolute;
    bottom: 0;
    right: 0;
    width: rem(845);
    height: rem(900);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include media-breakpoint-down(md) {
      width: rem(600);
      height: rem(640);
    }
  }

  &__bg {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 50%;
    //width: 1600px;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);

    //@include media-breakpoint-down(lg) {
    //  width: 1440px;
    //}

    //@include media-breakpoint-down(md) {
    //  width: 720px;
    //}
    //
    //@include media-breakpoint-down(sm) {
    //  width: 540px;
    //}

    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      width: rem(916);
      height: rem(916);
      left: rem(-133);
      top: rem(225);
      background: radial-gradient(50% 50% at 50% 50%, #675940 0%, rgba(103, 89, 64, 0) 100%);
      opacity: .75;

      @include media-breakpoint-down(md) {
        left: rem(-524);
        top: rem(-524);
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: rem(658);
      height: rem(658);
      left: rem(315);
      top: rem(121);
      background: radial-gradient(50% 50% at 50% 50%, #675940 0%, rgba(103, 89, 64, 0) 100%);
      opacity: .75;

      @include media-breakpoint-down(md) {
        left: auto;
        right: rem(-388);
        top: rem(-20);
      }
    }
  }
}

.js-hero-subtitle,
.js-hero-title,
.js-hero-footer {
  opacity: 0;
  transform: translateY(30px);

  @include media-breakpoint-down(md) {
    transform: translateY(20px);
  }
}

.js-hero-logos {
  opacity: 0;
}
