.single-product {
  &__inner {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(60);

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: rem(50);
    }
  }

  &__image {
    position: relative;
    height: rem(710);
    border-radius: rem(15);
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      height: rem(614);
    }

    @include media-breakpoint-down(md) {
      height: rem(370);
    }

    img {
      @include cover-image();
    }
  }

  &__chars {
    margin-bottom: rem(30);
  }

  &__btns {
    display: flex;
    align-items: center;
    margin-top: rem(30);

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__btn {
    @include media-breakpoint-down(md) {
      display: block;
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: rem(20);

      @include media-breakpoint-down(md) {
        margin-right: 0;
        margin-bottom: rem(20);
      }
    }
  }

  &__price {
    p {
      &:last-child {
        margin-bottom: 0;
      }

      &:not(:last-child) {
        margin-bottom: rem(10);
      }
    }

    ._old {
      font-weight: 100;
      color: $color-light-grey;
      text-decoration: line-through;
    }
  }
}