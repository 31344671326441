.product-list {
  &__item {
    &:not(:last-child) {
      margin-bottom: rem(100);

      @include media-breakpoint-down(md) {
        margin-bottom: rem(50);
      }
    }
  }
}