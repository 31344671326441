.mobile-pane {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-dark;
  color: $color-white;
  z-index: 11;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  overflow: hidden;
  transition: opacity 0.3s linear, visibility 0.3s linear;

  &._active {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }

  &__wrap {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    z-index: 3;
  }
  
  &__content {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: rem(100) rem(10) rem(50);
  }

  &__nav {
    margin-top: auto;
    margin-bottom: rem(50);
  }

  &__phone {
    margin-top: auto;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    display: block;
    width: 658px;
    height: 658px;
    right: -393px;
    top: 104px;
    background: radial-gradient(50% 50% at 50% 50%, #675940 0%, rgba(103, 89, 64, 0) 100%);
    opacity: 0.75;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    display: block;
    width: 916px;
    height: 916px;
    left: -323px;
    top: 235px;
    background: radial-gradient(50% 50% at 50% 50%, #675940 0%, rgba(103, 89, 64, 0) 100%);
    opacity: 0.75;
  }
}