.callback-block {
  padding: rem(30);
  background: rgba($color-black, 0.75);
  border: 1px solid $color-white;
  border-radius: 15px;
  color: $color-white;

  @include media-breakpoint-down(md) {
    padding: 0;
    background: transparent;
    border: 0;
    border-radius: 0;
  }
}