.card-list {
  &__item {
    &:not(&--full) {
      @include media-breakpoint-up(lg) {
        &:not(:nth-last-child(-n+2)) {
          margin-bottom: rem(100);
        }
      }

      &:not(:last-child) {
        @include media-breakpoint-down(md) {
          margin-bottom: rem(50);
        }
      }
    }

    &--full {
      &:not(:last-child) {
        margin-bottom: rem(100);

        @include media-breakpoint-down(md) {
          margin-bottom: rem(50);
        }
      }
    }
  }
}