:root {
  --gfs: 1600;

  @include media-breakpoint-down(sm) {
    --gfs: 375;
  }

  --btn-gradient-orange: linear-gradient(90deg, #7e180b 0%, #f53822 52.08%, #ffdb35 100%);
  --btn-gradient-green: linear-gradient(90deg, #15b9ff 0%, #05ff00 54.69%, #ffe500 100%);
  --btn-main-gradient: var(--btn-gradient-orange);
}
