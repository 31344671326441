.modal {
  color: $color-white;
}

.modal__overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  position: relative;
  padding: 30px;
  max-width: 600px;
  max-height: 100vh;
  background-color: $color-black;
  border: 1px solid $color-white;
  border-radius: 15px;
  overflow-y: auto;
  box-sizing: border-box;
}

.modal__header {
  margin-bottom: 30px;
}

.modal__title {
  margin-top: 0;
}

.modal__text {
  margin-top: 20px;
}

.modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  background: transparent;
  border: 0;
  cursor: pointer;
}

@keyframes mmfadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes mmfadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
  to { transform: translateY(0); }
}

@keyframes mmslideOut {
  from { transform: translateY(0); }
  to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.modal--success {
  text-align: center;
}

.modal--success .modal__header {
  margin-bottom: 0;
}
