.wa-section {
  position: relative;
  color: $color-white;
  background-color: $color-black;

  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black;
    opacity: 0.5;
  }

  &__inner {
    position: relative;
    z-index: 3;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(30);

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
      gap: rem(50);
    }
  }

  &__header {
    max-width: rem(643);
  }

  &__form {
    max-width: rem(600);

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__btn {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 53%;

    @include media-breakpoint-down(md) {
     display: none;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}