.main-menu {
  display: flex;
  align-items: center;
  font-size: rem(18);
  line-height: 130%;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    font-size: rem(30);
    line-height: 120%;
  }

  @include list-unstyled();

  &__item {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: $d-space;

      @include media-breakpoint-down(lg) {
        margin-right: rem(40);
      }

      @include media-breakpoint-down(md) {
        margin-right: 0;
        margin-bottom: rem(27)
      }
    }
  }

  &__link {
    position: relative;
    color: currentColor;

    @include hover() {
      &::after {
        transform: scaleX(1);
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      min-height: 1px;
      background-color: currentColor;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform .4s ease-in-out;
    }
  }
}