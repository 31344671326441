.time-counter {
  &__subtitle {
    font-size: rem(10);
    line-height: 1.3;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: rem(10);
  }

  &__element {
    display: flex;
    align-items: center;
    font-size: rem(70);
    font-weight: 600;
    line-height: .9;

    @include media-breakpoint-down(md) {
      font-size: rem(40);
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: rem(30);
    }

    span {
      font-size: rem(10);
      font-weight: 400;
    }
  }
}
