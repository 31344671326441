.dots {
  display: flex;

  span {
    flex-basis: auto;
    flex-shrink: 0;
    width: rem(15);
    height: rem(15);
    border-radius: 50%;
    background-color: $color-yellow;

    &:not(:last-child) {
      margin-right: rem(5);
    }

    &:nth-child(1) {
      background-color: $color-dark-red;
    }

    &:nth-child(2) {
      background-color: $color-orange;
    }
  }
}
