$btn-color-bg: $color-primary !default;
$btn-color-bg-hover: darken($color-primary, 10%) !default;

$btn-color-text: white !default;
$btn-color-text-hover: white !default;

$btn-font-weight: inherit !default;
$btn-font-size: 1rem !default;

$btn-padding-x: .75rem !default;
$btn-padding-y: .5rem !default;

$btn-border-color: transparent !default;
$btn-border-color-hover: transparent !default;
$btn-border-width: 1px !default;
$btn-border-radius: rem(40) !default;

.button {
  position: relative;
  display: inline-block;
  min-width: rem(220);
  padding: rem(19) rem(20);
  background-color: transparent;
  border: $btn-border-width solid transparent;
  border-radius: $btn-border-radius;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  transition: color 0.5s linear;

  @include media-breakpoint-down(md) {
    padding: rem(19) rem(20);
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate(-100%);
    border-radius: $btn-border-radius;
    opacity: 0;
    visibility: hidden;
    background-image: var(--btn-main-gradient);
    transition: transform .5s ease-out, opacity .5s linear, visibility .5s linear;
  }

  span {
    position: relative;
    display: inline-block;
    z-index: 3;
  }

  @include hover() {
    &::after {
      transform: translate(0);
      opacity: 1;
      visibility: visible;
    }
  }

  &--primary {
    border-color: $color-black;
    color: $color-black;

    @include hover() {
      color: $color-white;
    }
  }

  &--current {
    border-color: currentColor;
    color: currentColor;

    @include hover() {
      color: $color-white;
    }
  }

  &--white {
    border-color: $color-white;
    color: $color-white;
  }

  &--gradient {
    font-weight: bold;
    text-transform: uppercase;

    &::after {
      transform: none;
      opacity: 1;
      visibility: visible;
      background-image: $anim-gradient-2;
      background-size: 300% 100%;
      transition: transform .5s ease-out, opacity .5s linear, visibility .5s linear;
      animation: gradient-keys 15s linear infinite;
    }

    @include hover {
      &::after {
        opacity: 0;
        visibility: hidden;
        transform: translateX(-100%);
      }
    }
  }

  &--submit {
    min-width: rem(260);

    @include media-breakpoint-down(md) {
      display: block;
      width: 100%;
    }
  }

  &--full {
    display: block;
    width: 100%;
  }

  &--medium {
    padding: rem(19) rem(37);

    @include media-breakpoint-down(md) {
      display: block;
      padding: rem(19) rem(10);
    }
  }
}