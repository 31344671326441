.chars {
  display: grid;
  row-gap: rem(14);
  max-width: rem(500);

  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: rem(5);
      left: 0;
      width: 100%;
      height: 1px;
      border-top: 1px dashed currentColor;
    }

    span {
      position: relative;
      z-index: 2;
      display: inline-block;
      background-color: var(--bg-color);

      &:first-child {
        padding-right: rem(15);
        font-weight: 500;
      }

      &:last-child {
        padding-left: rem(15);
      }
    }
  }
}
