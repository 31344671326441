/**
 * Colors
 */

// Default text color
$color-text: #252423 !default;

// Theme colors
$color-primary: #007bff !default;
$color-secondary: #7f19e6 !default;
$color-white: #fff !default;
$color-light-grey: #8f8f8f !default;
$color-grey: #636363 !default;
$color-dark: #161314 !default;
$color-black: #252423 !default;
$color-total-black: #000 !default;
$color-orange: #f53822 !default;
$color-yellow: #ffdb35 !default;
$color-yellow-2: #e3e901 !default;
$color-dark-red: #7e180b !default;
$color-success: #00b300 !default;
$color-error: #c00 !default;
$color-bg: #f1f1f1 !default;
$color-red: #f53822 !default;
$color-green: #3ac637 !default;
$color-green-2: #07fb10 !default;
$primary-gradient: linear-gradient(90deg, #7e180b 0%, #f53822 52.08%, #ffdb35 100%);
$green-gradient: linear-gradient(90deg, #15b9ff 0%, #05ff00 54.69%, #ffe500 100%);
$anim-gradient: linear-gradient(90deg, #15b9ff, #05ff00, #ffe500, #15b9ff, #05ff00, #ffe500, #15b9ff, #05ff00, #ffe500);
$anim-gradient-2: linear-gradient(90deg, #15b9ff, #05ff00, #ffe500, #15b9ff, #05ff00, #ffe500);

$colors: (
  "default":   $color-text,
  "primary":   $color-primary,
  "secondary": $color-secondary,
  "white":     $color-white,
  "light":     $color-light-grey,
  "dark":      $color-dark,
  "black":     $color-black,
  "success":   $color-success,
  "error":     $color-error,
  "orange":    $color-orange,
  "grey":      $color-grey,
  "bg":        $color-bg
) !default;

// Links
$color-link-color: #007bff !default;
$color-link-color-hover: darken($color-link-color, 20%) !default;
