.friday-pane {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  padding: rem(17) 0 rem(15);
  background-color: $color-total-black;
  color: $color-white;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.3s linear, visibility 0.3s linear;
  font-size: rem(18);
  line-height: rem(23);

  @include media-breakpoint-down(md) {
    padding: rem(14) 0 rem(10);
    font-size: rem(16);
    line-height: rem(20);
  }

  &._active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      max-width: 100%;
      padding: 0;
    }
  }

  &__title {
    position: relative;
    flex: 0 0 auto;

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: rem(15);
      font-size: rem(20);
      overflow: hidden;
    }

    span {
      position: relative;
      flex: 0 0 auto;

      &:not(:first-child) {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      @include media-breakpoint-down(md) {
        padding-right: rem(30);
        animation: marquuee infinite 3s linear;
      }

      &::after {
        content: '*';
        position: absolute;
        top: rem(4);
        right: rem(10);
        display: none;
        background: $green-gradient;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include media-breakpoint-down(md) {
          display: block;
        }
      }
    }
  }

  &__text {
    margin-left: rem(54);

    @include media-breakpoint-down(lg) {
      margin-left: rem(30);
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__btn {
    width: rem(394);
    margin-left: rem(10);

    @include media-breakpoint-down(md) {
      margin-left: 0;
      padding: 0 10px;
      width: 100%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: rem(5);
    background: $green-gradient;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

@keyframes marquuee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}