.production {
  &__top {
    margin-bottom: $d-space;

    @include media-breakpoint-down(md) {
      margin-bottom: rem(30);
    }

    &-col {
      &:not(:last-child) {
        @include media-breakpoint-down(md) {
          margin-bottom: rem(15);
        }
      }
    }
  }

  &__bottom {
    &-col {
      &:not(:last-child) {
        @include media-breakpoint-down(md) {
          margin-bottom: rem(50);
        }
      }
    }
  }

  &__video {
    margin-bottom: $d-space;

    @include media-breakpoint-down(md) {
      margin-bottom: rem(30);
    }
  }
}