@font-face {
  font-family: 'GolosTextWeb';
  src:
    url('../assets/fonts/Golos-Text/Golos-Text_Regular.woff2') format('woff2'),
    url('../assets/fonts/Golos-Text/Golos-Text_Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GolosTextWeb';
  src:
    url('../assets/fonts/Golos-Text/Golos-Text_Bold.woff2') format('woff2'),
    url('../assets/fonts/Golos-Text/Golos-Text_Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GolosTextWeb';
  src:
    url('../assets/fonts/Golos-Text/Golos-Text_Medium.woff2') format('woff2'),
    url('../assets/fonts/Golos-Text/Golos-Text_Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
