.gallery {
  display: grid;
  gap: rem(60);
  grid-template-columns: repeat(2, 1fr);

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
    gap: rem(50);
  }

  &__image {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: rem(15);
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__item {
    height: rem(227);

    &:nth-child(3n+3) {
      @include media-breakpoint-up(lg) {
        grid-column: span 2;
        height: rem(323);
      }
    }
  }
}
