$border-light: 1px solid rgba(37, 36, 35, .15);

.point {
  $self: &;

  --l-width: 347;
  --origin: left top;
  --deg: 45;

  position: relative;

  &__btn {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      width: rem(32);
      height: rem(32);
    }
  }

  &__line {
    position: absolute;
    z-index: 2;
    top: calc(100% - 1px);
    left: 100%;
    width: calc(((var(--l-width) / var(--gfs)) * 100) * 1vw);
    height: 1px;
    background-color: $color-black;
    transform: rotate(calc(var(--deg) * 1deg));
    transform-origin: var(--origin);

    @include media-breakpoint-down(sm) {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: transparent;
      transform: none;
    }

    & > div {
      position: absolute;
      z-index: 3;
      top: calc(100% - #{vw(8)});
      left: calc(100% - #{vw(8)});
      display: flex;
      align-items: center;
      justify-content: center;
      width: vw(16);
      height: vw(16);
      border-radius: 50%;
      background-color: $color-white;
      border: $border-light;

      @include media-breakpoint-down(sm) {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      span {
        font-size: rem(18);
        line-height: 1;

        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }
  }

  &__icon {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: vw(32);
    height: vw(32);
    margin-right: vw(10);
    border-radius: 50%;
    background-color: $color-white;
    border: $border-light;
    transition: background-color .3s linear;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &-inner {
      position: relative;
      flex: 0 0 auto;
      width: vw(12);
      height: vw(12);

      span {
        position: absolute;
        background-color: $color-black;
        border-radius: 2px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        transition: opacity .3s ease-in-out;

        &:first-child {
          width: 100%;
          height: 1px;
        }

        &:last-child {
          height: 100%;
          width: 1px;
        }
      }
    }
  }

  &__title {
    border-bottom: 1px solid $color-black;
    padding: 0 vw(10) vw(10) 0;
    font-size: vw(24);
    line-height: 1.2;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__text {
    position: absolute;
    left: vw(28);
    top: calc(100% + #{vw(10)});
    padding: vw(15);
    min-width: vw(335);
    border-radius: vw(15);
    background-color: $color-white;
    border: $border-light;
    opacity: 0;
    font-size: vw(18);
    line-height: 1.3;
    visibility: hidden;
    transition: opacity .3s linear, visibility .3s linear;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &._inActive {
    #{$self}__icon {
      background-color: rgba(37, 36, 35, .3);
    }
  }

  &._active {
    #{$self}__icon {
      &-inner {
        span {
          &:last-child {
            opacity: 0;
          }
        }
      }
    }

    #{$self}__text {
      opacity: 1;
      visibility: visible;
    }
  }

  &--reverse {
    --origin: right top;

    #{$self}__btn {
      flex-direction: row-reverse;
    }

    #{$self}__icon {
      margin-right: 0;
      margin-left: rem(10);
    }

    #{$self}__line {
      left: auto;
      right: 100%;

      & > div {
        left: auto;
        right: calc(100% - #{vw(8)});
      }
    }

    #{$self}__title {
      padding: 0 0 vw(10) vw(10);
    }

    #{$self}__text {
      left: vw(-14);
    }
  }
}
