$base-gap: rem(30);

.line-card {
  $self: &;

  position: relative;
  border-top: 1px solid $color-grey;
  border-bottom: 1px solid $color-grey;

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    padding: rem(40) 0 rem(50);

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: rem(20) 0 rem(30);
    }
  }

  &__title {
    max-width: calc(50% + #{$base-gap});
    padding-right: $base-gap;
    width: 100%;
    margin-bottom: 0;
    transition: color .2s linear;

    @include media-breakpoint-down(md) {
      padding-right: 0;
      margin-bottom: rem(5);
    }

    span {
      display: block;
      transition: transform .3s ease;
    }
  }

  &__text {
    max-width: calc(50% - #{$base-gap});
    width: 100%;
    padding-right: $base-gap;
    color: $color-grey;
    transition: color .2s linear;

    @include media-breakpoint-down(md) {
      padding-right: 0;
      max-width: 100%;
    }
  }

  &__bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s linear, visibility 0.3s linear;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    }

    img {
      @include cover-image();
    }
  }

  @include hover() {
    @include media-breakpoint-up(lg) {
      color: $color-white;
    }

    #{$self}__text {
      @include media-breakpoint-up(lg) {
        color: $color-white;
      }
    }

    #{$self}__title {
      span {
        @include media-breakpoint-up(lg) {
          transform: translateX(rem(45));
        }
      }
    }

    #{$self}__bg {
      @include media-breakpoint-up(lg) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
