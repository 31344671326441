.form {
  &__label {
    margin-bottom: rem(15);
    font-weight: 700;
    font-size: rem(12);
    line-height: 1.3;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      font-size: rem(10);
    }
  }

  &__group {
    &:not(:last-child) {
      margin-bottom: rem(30);
    }

    &--submit {
      margin-top: rem(60);

      @include media-breakpoint-down(md) {
        margin-top: rem(30);
      }
    }
  }

  &__control {
    display: block;
    padding: rem(18) rem(30);
    width: 100%;
    border: 1px solid currentColor;
    border-radius: rem(60);
    background-color: transparent;

    @include placeholder() {
      color: currentColor;
      opacity: .6;
      transition: opacity .3s linear;
    }

    &:focus {
      outline: none;

      @include placeholder() {
        opacity: 1;
      }
    }
  }

  &__control + .label-error {
    padding-left: rem(30);
  }
}

.label-error {
  display: block;
  margin-top: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
  color: $color-red;
}
