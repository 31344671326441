/**
 * Links
 */

a {
  text-decoration: none;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
}

.link {
  &--underline {
    position: relative;

    @include hover() {
      &::after {
        transform: scaleX(1);
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      min-height: 1px;
      background-color: currentColor;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform .4s ease-in-out;
    }
  }
}
