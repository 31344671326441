.section {
  --bg-color: #{$color-bg};

  $self: &;

  padding: rem(100) 0 rem(100);

  @include media-breakpoint-down(md) {
    padding: rem(50) 0 rem(50);
  }

  &__header {
    margin-bottom: rem(60);
    max-width: rem(900);

    @include media-breakpoint-down(md) {
      margin-bottom: rem(50);
    }
  }

  &__dots {
    margin-bottom: rem(10);
  }

  &__title {
    font-weight: 400;
    margin-bottom: 0;
  }

  &__side {
    #{$self}__header {
      position: sticky;
      top: 0;
      left: 0;
      padding-top: rem(60);
      margin-top: rem(-60);

      @include media-breakpoint-down(lg) {
        position: static;
        padding-top: 0;
        margin-top: 0;
      }
    }
  }

  &__subtitle {
    margin-top: rem(30);
    font-size: rem(24);
    line-height: 120%;

    @include media-breakpoint-down(md) {
      margin-top: rem(20);
      font-size: rem(18);
    }
  }

  &__text {
    margin-top: rem(20);

    @include media-breakpoint-down(md) {
      margin-top: rem(20);
    }
  }

  &--horizontal {
    #{$self}__inner{
      display: flex;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }

    #{$self}__content{
      flex-basis: 0;
      flex-shrink: 1;
      flex-grow: 1;
    }

    #{$self}__header{
      flex-basis: auto;
      flex-shrink: 0;
      width: rem(456);
      max-width: none;
      margin-bottom: 0;
      margin-right: $d-space;

      @include media-breakpoint-down(lg) {
        margin-right: 0;
        margin-bottom: $d-space;
        width: 100%;
        max-width: rem(700);
      }

      @include media-breakpoint-down(md) {
        margin-bottom: rem(50);
      }
    }
  }

  &--dark {
    background-color: $color-black;
    color: $color-white;
  }

  &--page {
    padding-top: rem(160);

    @include media-breakpoint-down(md) {
      padding-top: rem(100);
    }
  }

  &--medium {
    #{$self}__subtitle {
      max-width: rem(570);
    }
  }

  &--between {
    #{$self}__inner{
      display: flex;
      align-items: flex-start;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }

    #{$self}__header {
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        margin-bottom: rem(50);
      }
    }

    #{$self}__header,
    #{$self}__content{
      max-width: 50%;
      width: 100%;

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }

    #{$self}__content {
      padding-top: rem(25);

      @include media-breakpoint-down(md) {
        padding-top: 0;
      }
    }

    #{$self}__title {
      max-width: rem(600);
    }
  }
}
