/**
 * Carousel
 */

.carousel {
  &.is-hidden {
    display: none;
  }

  &.flickity-enabled {
    opacity: 1;
  }

  .carousel-cell {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    @include media-breakpoint-up(lg) {
      width: 33.3333%;
    }
  }
}
