.phone-block {

  @include media-breakpoint-down(md) {
    text-align: center;
  }

  &__work {
    position: relative;
    display: block;
    padding-left: rem(12);
    margin-bottom: rem(6);
    font-size: rem(12);
    line-height: 110%;
    color: $color-light-grey;

    @include media-breakpoint-down(md) {
      display: inline-block;
    }

    &::after {
      content: '';
      position: absolute;
      top: rem(4);
      left: 0;
      width: rem(7);
      height: rem(7);
      border-radius: 50%;
      background-color: $color-green;
      animation: opacity 2s infinite linear;
    }
  }

  &__link {
    color: currentColor;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}