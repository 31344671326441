/**
 * Imported via /node_modules
 *
 * Modifications
 * http://getbootstrap.com/docs/4.1/getting-started/theming/
 * Import bootstrap-grid.scss
 * The only change comparing to the original structure is that we don't import
 * whole "variables.scss" from Bootstrap as we don't need all of them.
 *
 * We comment // @import "variables"; and define Grids and Breakpoints variables directly here.
*/

/**
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "bootstrap/scss/functions";

// @import "variables";

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xlg: 1439px,
  xl: 1600px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 100%,
  xl: 1600px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 60px !default;
$grid-gutter-width-tablet: 30px !default;
$grid-gutter-width-mobile: 10px !default;
$enable-grid-classes: true !default;

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;

@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/mixins/grid-framework";
@import "bootstrap/scss/mixins/grid";

@import "bootstrap/scss/grid";
@import "bootstrap/scss/utilities/display";
@import "bootstrap/scss/utilities/flex";

.container {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;

  @include media-breakpoint-down(lg) {
    padding-left: $grid-gutter-width-tablet;
    padding-right: $grid-gutter-width-tablet;
  }

  @include media-breakpoint-down(md) {
    padding-left: $grid-gutter-width-mobile;
    padding-right: $grid-gutter-width-mobile;
  }
}

.row {
  @include media-breakpoint-down(md) {
    margin-left: -$grid-gutter-width-mobile;
    margin-right: -$grid-gutter-width-mobile;
  }
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xlg-1,
.col-xlg-2,
.col-xlg-3,
.col-xlg-4,
.col-xlg-5,
.col-xlg-6,
.col-xlg-7,
.col-xlg-8,
.col-xlg-9,
.col-xlg-10,
.col-xlg-11,
.col-xlg-12,
.col-xlg,
.col-xlg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  @include media-breakpoint-down(md) {
    padding-left: $grid-gutter-width-mobile;
    padding-right: $grid-gutter-width-mobile;
  }
}
