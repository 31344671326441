.custom-checkbox {
  $self: &;
  position: relative;

  &__input {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:checked + #{$self}__icon {
      opacity: 1;

      svg {
        opacity: 1;
      }
    }

    &._error + #{$self}__icon {
      border-color: $color-red;
    }
  }

  &__icon {
    position: absolute;
    z-index: 1;
    left: rem(4);
    top: rem(2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(20);
    height: rem(20);
    border: 1px solid currentColor;
    opacity: .6;
    transition: opacity .3s linear;

    svg {
      width: rem(14);
      height: rem(10);
      opacity: 0;
      transition: opacity .3s linear;
    }
  }

  &__label {
    padding-left: rem(36);
  }

  .label-error {
    display: none;
  }
}
