.product-card {
  $self: &;

  &__inner {
    align-items: center;
  }

  &__friday {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: rem(16) rem(10);
    text-transform: uppercase;
    background-color: $color-total-black;

    @include media-breakpoint-down(md) {
      padding: rem(23) rem(10);
    }

    span {
      color: $color-white;
      font-size: rem(40);
      font-weight: 700;
      line-height: 1;

      @include media-breakpoint-down(lg) {
        font-size: rem(28);
      }

      @include media-breakpoint-down(md) {
        font-size: rem(20);
      }

      &:not(:last-child) {
        margin-right: rem(14);

        @include media-breakpoint-down(md) {
          margin-right: rem(10);
        }
      }
    }
  }
  
  &__content {
    max-width: rem(600);
  }

  &__title {
    margin-bottom: $d-space;

    @include media-breakpoint-down(md) {
      margin-bottom: rem(20);
    }
  }

  &__text {
    margin-bottom: $d-space;

    @include media-breakpoint-down(md) {
      margin-bottom: rem(20);
    }
  }

  &__chars {
    text-transform: uppercase;
    font-weight: 500;

    p {
      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: rem(44);

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-top: rem(20);
      align-items: flex-start;
    }
  }

  &__price {
    margin-right: $d-space;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      margin-right: 0;
    }
  }

  &__btn {
    @include media-breakpoint-down(md) {
      display: block;
      width: 100%;
      margin-top: rem(30);
    }
  }

  &--reverse {
    #{$self}__inner {
      flex-direction: row-reverse;
    }
  }

  &__image {
    position: relative;
    height: rem(710);
    border-radius: rem(15);
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      height: rem(614);
    }

    @include media-breakpoint-down(md) {
      height: rem(370);
      margin-bottom: rem(50);
    }

    img {
      z-index: 2;
      @include cover-image();
    }
  }
}