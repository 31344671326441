.catalog-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: rem(30);
  row-gap: rem(50);

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: rem(20);
    row-gap: rem(40);
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}