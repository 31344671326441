.partners {
  display: flex;
  align-items: center;

  &__item {
    height: rem(60);

    &:not(:last-child) {
      margin-right: $d-space;
    }

    img {
      max-height: 100%;
    }
  }
}
