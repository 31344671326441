.video {
  $self: &;

  position: relative;
  height: rem(630);
  cursor: pointer;

  @include media-breakpoint-down(md) {
    height: rem(300);
  }

  video {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__icon {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    width: rem(150);
    height: rem(150);
    transition: transform .5s ease-in-out, opacity 0.3s linear, visibility 0.3s linear;
    backface-visibility: hidden;
    pointer-events: none;
    
    svg {
      fill: none
    }
  }

  @include hover() {
    #{$self}__icon {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }

  &.isPlay {
    #{$self}__icon {
      opacity: 0;
      visibility: hidden;
    }
  }
}