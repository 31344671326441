.offers-card {
  $self: &;

  position: relative;
  height: rem(442);
  border: 1px solid $color-black;
  border-radius: rem(15);
  overflow: hidden;

  @include media-breakpoint-down(md) {
    height: rem(412);
  }

  &__content {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: rem(32) rem(30) rem(27);

    @include media-breakpoint-down(md) {
      padding: rem(30);
    }
  }

  &__text {
    color: $color-light-grey;
    max-width: rem(300);
    transition: color .2s linear;
  }

  &__title {
    margin-bottom: rem(30);
    transition: color .2s linear;
  }

  &__price {
    margin-top: auto;
    margin-bottom: rem(30);
    transition: color .2s linear;
  }

  &__btn {
    flex-basis: auto;
    max-width: 100%;

    @include media-breakpoint-down(md) {
      display: block;
      width: 100%;
    }
  }

  &__bg {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s linear, visibility 0.2s linear;

    img {
      @include cover-image();
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    }
  }

  @include media-breakpoint-up(lg) {
    @include hover() {
      color: $color-white;
    
      #{$self}__text {
        color: $color-white;
      }
    
      #{$self}__bg {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}