/*!
 * Project: Project Name
 * Author: Name
 * E-mail: E-mail
 * Website:
 */

/**
 * This is where all of the stylesheets are compiled.
 * They are processed in the order they are imported
 * to ensure the consistent increase of specificity.
 *
 *    ITCSS Methodology: Inverted Triangle CSS
 *
 *    **************   1. Settings
 *     ************    2. Tools
 *      **********     3. Generic
 *       ********      4. Elements
 *        ******       5. Objects
 *         ****        6. Components
 *          **         7. Utilities
 *
 * 1. Settings.........Global configuration and variables.
 * 2. Tools............Functions and mixins.
 * 3. Generic..........Ground zero styles.
 *                     (normalizing.css, box-sizing etc...)
 * 4. Elements.........Unclassed (bare) HTML element
 *                     (like H1, Ul, A etc...)
 * 5. Objects..........Common non-cosmetic structural design patterns.
 *                     e.g containers, rows, grids, colums etc...
 * 6. Components.......Specific cosmetic elements of UI.
 *                     e.g. buttons, forms, header, footer etc...
 * 7. Utilities........Helpers and overrides.
 *
 *    Modules..........Multi-part components.
 *                     e.g. Navbar (HTML, CSS and JS).
 *    Shame............All the CSS, hacks and things we are not proud of.
 */

@import "settings/_colors.scss";
@import "settings/_fonts.scss";
@import "settings/_global.scss";
@import "settings/_spacing.scss";
@import "settings/_transitions.scss";
@import "settings/_typography.scss";
@import "tools/_aspect-ratio.scss";
@import "tools/_center.scss";
@import "tools/_clearfix.scss";
@import "tools/_cover-image.scss";
@import "tools/_font-size.scss";
@import "tools/_hover.scss";
@import "tools/_list-inline.scss";
@import "tools/_list-unstyled.scss";
@import "tools/_max-width-center.scss";
@import "tools/_overlay.scss";
@import "tools/_placeholder.scss";
@import "tools/_pseudo.scss";
@import "tools/_rem.scss";
@import "tools/_size.scss";
@import "tools/_space.scss";
@import "tools/_triangle.scss";
@import "generic/_box-sizing.scss";
@import "generic/_font-face.scss";
@import "generic/_normalize.scss";
@import "generic/_print.scss";
@import "objects/_bootstrap.grid.scss";
@import "objects/_list-inline.scss";
@import "objects/_list-unstyled.scss";
@import "components/_collapse.scss";
@import "components/_footer.scss";
@import "components/_headings.scss";
@import "components/_map.scss";
@import "components/_modal.scss";
@import "components/_page.scss";
@import "components/_section.scss";
@import "components/_tabs.scss";
@import "utilities/_aspect-ratio.scss";
@import "utilities/_background-size.scss";
@import "utilities/_clearfix.scss";
@import "utilities/_colors.scss";
@import "utilities/_font-family.scss";
@import "utilities/_font-size.scss";
@import "utilities/_font-weight.scss";
@import "utilities/_opacity.scss";
@import "utilities/_overflow.scss";
@import "utilities/_root.scss";
@import "utilities/_spacing.scss";
@import "utilities/_text-alignment.scss";
@import "utilities/_text-style.scss";
@import "utilities/_visibility.scss";
@import "elements/_animation.scss";
@import "elements/_blockquote.scss";
@import "elements/_chars.scss";
@import "elements/_custom-checkbox.scss";
@import "elements/_document.scss";
@import "elements/_dots.scss";
@import "elements/_form.scss";
@import "elements/_friday-text.scss";
@import "elements/_links.scss";
@import "elements/_lists.scss";
@import "elements/_media.scss";
@import "elements/_more-btn.scss";
@import "elements/_paragraphs.scss";
@import "elements/_point.scss";
@import "elements/_tables.scss";
@import "elements/_text.scss";
@import "elements/_time-counter.scss";
@import "elements/_wrap.scss";
@import '../../node_modules/swiper/swiper.scss';

@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/templates/footer/footer.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/templates/header/header.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/modules/carousel/carousel.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/modules/contacts-section/contacts-section.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/modules/form-section/form-section.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/modules/hero-section/hero-section.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/modules/section/section.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/modules/showroom/showroom.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/modules/title-section/title-section.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/modules/wa-section/wa-section.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/elements/burger/burger.module.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/elements/button/button.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/elements/catalog-card/catalog-card.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/elements/info-card/info-card.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/elements/line-card/line-card.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/elements/logo/logo.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/elements/offers-card/offers-card.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/elements/product-card/product-card.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/elements/single-product/single-product.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/elements/video/video.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/callback-block/callback-block.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/card-list/card-list.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/catalog-list/catalog-list.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/contacts-block/contacts-block.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/friday-form/friday-form.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/friday-pane/friday-pane.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/gallery/gallery.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/main-menu/main-menu.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/mobile-pane/mobile-pane.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/offers-list/offers-list.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/partners/partners.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/phone-block/phone-block.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/product-list/product-list.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/product-map/product-map.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/production/production.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/reviews-slider/reviews-slider.scss";
@import "/Users/shadowbox/Documents/PROJECTS GIT/Arte/src/blocks/components/tech-list/tech-list.scss";
@import 'shame';
