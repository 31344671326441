.text {
  &-small {
    font-size: rem(14);
    line-height: rem(15);
  }

  &-medium-well {
    font-size: rem(20);
    line-height: 1.1;

    @include media-breakpoint-down(md) {
      font-size: rem(18);
    }
  }

  &-medium {
    font-size: rem(24);
    line-height: 120%;

    @include media-breakpoint-down(md) {
      font-size: rem(18);
    }
  }

  &-gray {
    color: $color-grey;
  }

  p {
    margin-bottom: rem(20);

    @include media-breakpoint-down(md) {
      margin-bottom: rem(15);
    }
  }

  ul {
    padding-left: rem(8);
    list-style: none;

    li {
      position: relative;
      margin-bottom: 0;

      &::after {
        content: '';
        position: absolute;
        top: .66rem;
        left: rem(-9);
        width: rem(4);
        height: rem(4);
        border-radius: 50%;
        background-color: currentColor;
      }
    }
  }

  &-gradient {
    background: $green-gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}
