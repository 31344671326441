.friday-text {
  font-weight: 600;

  ._green {
    color: $color-green-2;
  }

  ._yellow {
    color: $color-yellow-2;
  }
}
