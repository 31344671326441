.info-card {
  $self: &;

  &__image {
    position: relative;
    height: rem(227);
    overflow: hidden;

    img {
      display: block;
      transform: scale(1) translateZ(0);
      backface-visibility: hidden;
      transition: transform .5s ease-in-out;
      
      @include cover-image();
    }
  }

  &__content {
    margin-top: rem(40);
    padding-top: rem(30);
    border-top: 1px solid $color-black;

    @include media-breakpoint-down(md) {
      margin-top: rem(30);
      padding-top: rem(20);
    }
  }

  &__title {
    margin-bottom: rem(30);
    max-width: rem(380);

    @include media-breakpoint-down(lg) {
      max-width: rem(600);
    }

    @include media-breakpoint-down(md) {
      margin-bottom: rem(20);
      max-width: 100%;
    }

    br {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__text {
    max-width: rem(412);
    color: $color-light-grey;

    @include media-breakpoint-down(lg) {
      max-width: rem(600);
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }

  @include hover() {
    #{$self}__image {
      img {
        transform: scale(1.15) translateZ(0);
      }
    }
  }

  &--full {
    #{$self}__image {
      height: rem(324);

      @include media-breakpoint-down(md) {
        height: rem(227);
      }
    }

    #{$self}__texts {
      display: flex;
      margin: 0 rem(-50);

      @include media-breakpoint-down(lg) {
        margin: 0 rem(-30);
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
        margin: 0 rem(-10);
      }
    }

    #{$self}__text {
      max-width: 50%;
      width: 100%;
      padding: 0 rem(50);

      @include media-breakpoint-down(lg) {
        padding: 0 rem(30);
      }

      @include media-breakpoint-down(md) {
        max-width: 100%;
        padding: 0 rem(10);
      }

      &:not(:last-child) {
        @include media-breakpoint-down(md) {
          margin-bottom: rem(20);
        }
      }
    }

    #{$self}__title {
      max-width: 100%;
    }
  }

  &--without {
    #{$self}__text {
      max-width: 100%;
    }

    #{$self}__title {
      max-width: 100%;
    }

    #{$self}__content {
      border-color: $color-light-grey;
      margin-top: 0;
    }
  }
}