.header {
  position: absolute;
  z-index: 12;
  top: 0;
  left: 0;
  width: 100%;
  padding: rem(50) 0;
  font-size: rem(18);
  line-height: rem(23);
  color: $color-white;

  @include media-breakpoint-down(md) {
    padding: $d-space-mobile 0;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nav {
    padding-right: rem(50);

    @include media-breakpoint-down(lg) {
      padding-right: 0;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__phone {
    margin-top: rem(-20);

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__burger {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &--dark {
    color: $color-dark;
  }
}
