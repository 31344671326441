.contacts-block {
  &__item {
    @include media-breakpoint-up(lg) {
      margin-bottom: rem(40);
    }

    &:not(:last-child) {
      @include media-breakpoint-down(md) {
        margin-bottom: rem(20);
      }
    }
  }

  &__title {
    margin-bottom: rem(15);
    color: $color-grey;

    @include media-breakpoint-down(md) {
      margin-bottom: rem(5);
    }
  }

  &__text {
    max-width: 300px;

    a {
      color: $color-white;
    }

    p {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 10px;

        @include media-breakpoint-down(md) {
          margin-bottom: rem(5);
        }
      }
    }
  }
}