.title-section {
  position: relative;
  padding: rem(140) $d-space;
  color: $color-white;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding: rem(90) 0;
  }

  &__text {
    position: relative;
    z-index: 3;
    display: flex;
    margin-bottom: 0;
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      display: block;
      white-space: normal;
      max-width: 720px;
      padding-left: 10px;
      padding-right: 10px;
      margin: 0 auto;
    }

    @include media-breakpoint-down(sm) {
      max-width: 540px;
    }

    span {
      display: block;

      @include media-breakpoint-down(md) {
        max-width: rem(370);
      }

      &:not(:last-child) {
        margin-right: rem(30);

        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }

      &:not(:first-child) {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  &__bg {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      @include cover-image();
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    }
  }
}
