.reviews-slider {
  &__item {
    width: 225px;
  }

  &__card {
    position: relative;
    display: block;
    height: rem(400);
    width: 100%;
    overflow: hidden;
    border-radius: rem(15);
    border: 1px solid $color-black;
    user-select: none;

    img {
      @include cover-image();
    }
  }
}
