/**
 * Document defaults (html, body)
 */

body,
html {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

html {
  font-family: $font-family;
  font-size: $font-size-base;
  color: $color-text;
  line-height: $line-height-base;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  background-color: $color-bg;

  &._lock {
    overflow: hidden;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 6px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: $color-orange;
  border-radius: 0;
  border: 0;
  transition: all .2s ease-in-out;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  transition: all .2s ease-in-out;
}
